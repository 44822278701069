import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { CustomModal  } from '@cd/sdds-common-components-react';
import { updateDisturbanceMessageStatus } from '../../../adapters/DisturbanceMessageAdapter';
import { t } from 'i18next';

type DisturbanceMessageActionModalProps = {
  show: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  onAction: (event: DisturbanceMessageActionEventType) => void;
  data: DisturbanceMessageActionDataType |undefined;
};

const DisturbanceMessageActionModal = (props: DisturbanceMessageActionModalProps) => {
  const {
    show,
    onClose,
    onAction,
    data,
  } = props;

  const [title, setTitle] = useState<string>('');

  const handleActionEvent = () => {
    const event = {
      id: data?.message.id as string,
      action: data?.action as DisturbanceMessageModalActionType,
    };
    updateDisturbanceMessageStatus(data?.message.id as string, data?.action as string)
      .then(() => {
        onAction({ ...event, success: true });
      })
      .catch(error => {
        onAction({ ...event, success: false });
        console.error(error);
      });
  };

  useEffect(() => {
    switch (data?.action) {
      case 'stop':
        setTitle('Do you want to stop publishing the message?');
        break;
      case 'cancel':
        setTitle('Do you want to delete the message?');
        break;
      default:
        setTitle('');
    }
  }, [data]);

  return (
    <CustomModal
      id="disturbance-message-action-modal"
      show={show}
      title={title as string}
      handleClose={() => onClose(false)}
      actions={(
        <>
          <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-primary mr-8 bttn" onClick={() => handleActionEvent()}>{t("Yes")}</button>
          <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn" onClick={() => onClose(false)}>{t("No")}</button>
        </>
      )}
    >
      <p>
        { ` ${data?.message.title}` }
      </p>
      <p>
        <strong>{t("TS_core:SystemName")}</strong>
        :
        { ` ${data?.message.systemName}` }
      </p>
      <p>
        <strong>{t("TS_core:StartTime")}</strong>
        :
        { ` ${data?.message.startTime}` }
      </p>
      <p>
        <strong>{t("TS_core:EndTime")}</strong>
        :
        { ` ${data?.message.endTime}` }
      </p>
    </CustomModal>
  );
};

export default DisturbanceMessageActionModal;
