import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';

const getDisturbanceMessages = () : AxiosPromise<Array<DisturbanceMessageType>> => csttFacadeApi.get('/csttfacade/disturbancemessage/v1/messages');
const getSystems = () : AxiosPromise<Array<string>> => csttFacadeApi.get('/csttfacade/disturbancemessage/v1/systems');
const getDisturbanceTypes = () : AxiosPromise<Array<string>> => csttFacadeApi.get('/csttfacade/disturbancemessage/v1/types');
const getDisturbanceTemplateHeaders = (locale: string) : AxiosPromise<Array<DisturbanceTemplateHeaderType>> => csttFacadeApi.get('/csttfacade/disturbancemessage/v1/templates/headers', { params: { cultureCode: locale } });
const getDisturbanceTemplateByKey = (key: string) : AxiosPromise<Array<DisturbanceMessageContentType>> => csttFacadeApi.get('/csttfacade/disturbancemessage/v1/templates', { params: { key } });
const updateDisturbanceMessageStatus = (id: string, action: string) => csttFacadeApi.put(`/csttfacade/disturbancemessage/v1/messages/${id}`, null, { params: { action } });
const createDisturbanceMessage = (message: NewDisturbanceMessageType) => csttFacadeApi.post('/csttfacade/disturbancemessage/v1/message', message);


export {
  getDisturbanceMessages,
  getSystems,
  getDisturbanceTypes,
  getDisturbanceTemplateHeaders,
  getDisturbanceTemplateByKey,
  updateDisturbanceMessageStatus,
  createDisturbanceMessage,
};
