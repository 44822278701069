import axios, { InternalAxiosRequestConfig } from 'axios';
import { msalInstance, loginRequest } from '../authConfig';

export const jwtApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
});

jwtApi.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  try {
    const accounts = msalInstance.getAllAccounts() as any;
    const account = accounts[0];
    msalInstance.setActiveAccount(account);
    await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    }).then((response) => {
      const bearer = `Bearer ${response.idToken}`;         
      config.headers.set('Authorization', bearer);
    });
  } catch (error) {
    console.log(error)
  }
  return config;
});

export default jwtApi;
