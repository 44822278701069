import React, { useState, useEffect } from 'react';
import { useIsAuthenticated, AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import './App.css';
import { callCsttFacadeApis } from './adapters/UserAdapter';
import { loginRequest } from './authConfig';
import { Spinner, UserSettings, MOpinionFeedback, NoRoleDisclaimer } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { RBACProvider, RBACWrapper } from 'react-simple-rbac';
import DisturbanceMessages from './pages/DisturbanceMessages';
import { t } from 'i18next';
import Header from './components/Header';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [loggedInProfileInfo, setLoggedInProfileInfo] = useState<ProfileInformationType>({
    "firstName": "",
    "lastName": "",
    "email": "",
    "role": "",
    "permissions": {
      "developer": false
    },
    "settings": {
      "csddashboard": "",
      "unit": "",
      "language": "en-GB"
    },
    "scaniaId": "",
    "impersonationAccess": false
  });

  const { i18n } = useTranslation();
  const [apiResponse, setApiResponse] = useState({});
  const [apiCalled, setApiCalled] = useState<string>('getProfileInfo');

  const [profileInfoLoader, setProfileInfoLoader] = useState<AsyncStateType>({ loading: true, error: false });

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');
  //For header to maintain state of which app user is currently on
  const currentApp = t('TS_temp:DisruptionMessages');

  useEffect(() => {
    if (isAuthenticated) {
      setApiCalled('getProfileInfo');
      callCsttFacadeApis(apiCalled, setApiResponse, setProfileInfoLoader, []);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (profileInfoLoader.error) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
  }, [profileInfoLoader]);

  useEffect(() => {
    setLoggedInProfileInfo(apiResponse as ProfileInformationType)
  }, [apiResponse]);

  useEffect(() => {
    i18n.changeLanguage(loggedInProfileInfo?.settings?.language || 'en-GB');
  }, [loggedInProfileInfo, i18n]);


  return (
    <div className="App">
      <sdds-theme global="true" />
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
      </MsalAuthenticationTemplate>
      <AuthenticatedTemplate>
        {profileInfoLoader.loading && (<Spinner />)}
        {(!(profileInfoLoader.error || profileInfoLoader.loading)) && (
          <RBACProvider roles={[loggedInProfileInfo.role]} >
            {(!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
              <>
                <NoRoleDisclaimer />
              </>
            ) :
              (
                <Router >
                  <RBACWrapper oneOf requiredRoles={["Admin", "GTS"]}>
                    <Header
                      profileInfo={loggedInProfileInfo}
                      currentApp={currentApp}
                      setLoggedInProfileInfo={setLoggedInProfileInfo}                                >
                    </Header>
                  </RBACWrapper>
                  <RBACWrapper oneOf requiredRoles={["LoTH", "FQ", "Basic", "BasicLimited", "Assistance"]}>
                  <tds-banner 
                    icon="error" 
                    variant="error"
                    header="Access Denied" 
                    subheader="You need an Admin or GTS role to access this page."> 
                  </tds-banner>
                </RBACWrapper>
                  <Routes>
                    <Route index element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS"]}><DisturbanceMessages /></RBACWrapper></>} />
                    <Route path="/user-settings" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS"]}><UserSettings
                      userDetails={loggedInProfileInfo}
                      setLoggedInProfileInfo={setLoggedInProfileInfo}
                    /></RBACWrapper></>} />
                  </Routes>
                </Router>
              )}
          </RBACProvider>
        )}
        {profileInfoLoader.error && (<p>{'Profile Info Api : ' + profileInfoLoader.errorMessage}</p>)}
      </AuthenticatedTemplate>
      <MOpinionFeedback />
    </div>
  );
};

export default App;
