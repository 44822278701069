/*eslint-disable*/
import React, {
  useState,
  useEffect,
  
} from 'react';
import { Dropdown, DateTimePicker, Spinner, Banner, Toast, useToast } from '@cd/sdds-common-components-react';
import 'react-datepicker/dist/react-datepicker.css';
import './AddDisturbanceMessageModal.css';
import { utcFormatFromDate } from '../../../helpers/timeFormatter';
import { t } from 'i18next';

type SetPropertiesStepProps = {
  disturbanceProperties: DisturbanceMessagePropertiesType;
  setDisturbanceProperties : Function;
};

const SetPropertiesStep = (props: SetPropertiesStepProps) => {
  const { disturbanceProperties, setDisturbanceProperties } = props;  
  const [withEndtime, setWithEndtime] = useState<boolean>(disturbanceProperties.endTime !== null);  
  const [system, setSystem] = useState(disturbanceProperties.system || '');
  const [type, setType] = useState(disturbanceProperties.disturbanceType || '');
  const [show, setShow] = useState(false);
  const { toastQueue, addToast } = useToast();

  

  const setProperty = <K extends keyof DisturbanceMessagePropertiesType>(key: K, value: DisturbanceMessagePropertiesType[K]): void => {
    const newProperties: DisturbanceMessagePropertiesType = { ...disturbanceProperties, [key]: value };
    setDisturbanceProperties(newProperties);    
  };

  const handleWithEndtimeChange = (newState: boolean): void => {
    setWithEndtime(newState);
    if (!newState) {
      setProperty('endTime', null);
    }
  };  

  const verifyEndDate = (date: Date) =>  {
   date > disturbanceProperties.startTime? setProperty('endTime', date) : addToast({ text: t("TS_temp:StartEndTimeValidationMsg"), type: 'error' });
  }
  useEffect(() => {
    if (system)
      setProperty('system', system);
  }, [system]) 

  useEffect(() => {
    if (type)
      setProperty('disturbanceType', type);
  }, [type]) 
  
  useEffect(() => {      
    setShow(!(disturbanceProperties.systemsError.loading || disturbanceProperties.typesError.loading))
  }, [disturbanceProperties.allSystems, disturbanceProperties.allTypes]) 

  /*when start and end times are changed, reset default message-content start and end times displayed */
  useEffect(() => {
    if(disturbanceProperties.endTime && disturbanceProperties.startTime > disturbanceProperties.endTime){
      setProperty('endTime', disturbanceProperties.startTime);
      addToast({ text: t("TS_temp:StartEndTimeValidationMsg"), type: 'error' })
    }    
    disturbanceProperties.messageContentStartTime = disturbanceProperties.startTime;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disturbanceProperties.startTime])
  
  useEffect(() => {
    disturbanceProperties.messageContentEndTime = disturbanceProperties.endTime;
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [disturbanceProperties.endTime])
  
 
  return (
    <div className='add-message-modal-screen'>     
      {(!show) && (<Spinner/>)} 
      {
        (disturbanceProperties.systemsError.error || disturbanceProperties.typesError.error) && (
          <div className="sdds-row">
            <div className="sdds-col">
              <Banner text={disturbanceProperties.systemsError.errorMessage || disturbanceProperties.typesError.errorMessage || 'Unable to connect with backend.'} type="error" />
            </div>
          </div>
        )
      }
      {
        (show) && (
          <>
            <div className="sdds-row drop">
              <div className="sdds-col mod-col" >
                <div className='label-size'>{t("TS_core:System")}</div>
                {(disturbanceProperties.allSystems.length > 0 && (
                <Dropdown data={disturbanceProperties.allSystems} placeholder={t("TS_temp:ChooseSystem")} searchable selectedValue={system} setSelectedValue={setSystem} />
                ))}
              </div>
              <div className="sdds-col">
              <div className='label-size'>{t("TS_core:Type")}</div>
              {(disturbanceProperties.allTypes.length > 0 && (
                <Dropdown data={disturbanceProperties.allTypes} placeholder={t("TS_temp:ChooseMessageType")} selectedValue={type} setSelectedValue={setType} />
                ))} 
              </div>
            </div>

            <div className="sdds-row mt-32">
              <div className="sdds-col mod-col">
                <p>
                  <sdds-icon id="time-display-info1" name="scania-information" />
                  <span className='label-size'> {t("TS_temp:AllTimeinUTC")}</span>
                </p>
              </div>
            </div>

            <div className="sdds-row datepick">
              <div className="sdds-col mod-col">
                {/* TODO We shold change with new tegel Datetime Picker */}
                <DateTimePicker
                  time
                  selectedDate={disturbanceProperties.startTime}
                  onChange={(date: Date) => setProperty('startTime', date)}
                  maxDate={new Date(Date.now() + 29999222225)}// Adding 1year as a quick fix for selecting future date. 
                
                />
                <div className='utc-conversion'>{t("TS_temp:SelectedStartTime")} : {utcFormatFromDate(disturbanceProperties.startTime) + ' (UTC)'}</div>
              </div>
              <div className="sdds-col">
                {/* TODO We shold change with new tegel Datetime Picker */}
                {
                  withEndtime && (
                    <DateTimePicker
                      time
                      selectedDate={disturbanceProperties.endTime? disturbanceProperties.endTime : disturbanceProperties.startTime}
                      onChange={(date: Date) => verifyEndDate(date)}
                      minDate={disturbanceProperties.startTime}
                      maxDate={new Date(Date.now() + 29999222225)}// Adding 1year as a quick fix for selecting future date. 
                    />

                  )
                }
                {
                  withEndtime &&(<div className='utc-conversion'>{t("TS_temp:SelectedEndTime")} :  {utcFormatFromDate(disturbanceProperties.endTime? disturbanceProperties.endTime : new Date()) + ' (UTC)'}</div>)}
              </div>
            </div>
            <div className="sdds-row mt-16">
              <div className="sdds-col mod-col">
                <div className="sdds-checkbox-item">
                  <label className="sdds-form-label" htmlFor="new-disturbance-message-modal-set-endtime">
                    <input className="sdds-form-input" onChange={() => handleWithEndtimeChange(!withEndtime)} type="checkbox" name="cb-example" id="new-disturbance-message-modal-set-endtime" checked={withEndtime} />
                    &nbsp;{t("TS_core:SetEndTime")}
                  </label>
                </div>
              </div>
            </div>
          </>
        )
      }
      <Toast toastQueue={toastQueue} />
    </div>
  );
};

export default SetPropertiesStep;
