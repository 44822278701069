import React, { useState, useEffect } from 'react';
import { formatCultureCode } from '../../../helpers/cultureCodeMapper';
import { replacePlaceholders } from '../../../helpers/stringMutations';
import { t } from 'i18next';

type TemplateAccordionProps = {
  allTemplates: Array<DisturbanceMessageContentType>;
  editable: boolean;
  replacementPattern?: { placeholder: string, replacement: string }[];
  setNextValidated?:Function
};

const TemplateAccordion = (props: TemplateAccordionProps) => {

  const {
    allTemplates, editable, replacementPattern, setNextValidated,
  } = props;
  const [displayedtemplates, setDisplayedTemplates] = useState<Array<DisturbanceMessageContentType>>(allTemplates);

  const validation = ()=>{
    let headerEmptyFlag = false;
    allTemplates.forEach((template)=>{
      if(template.header === '')
      headerEmptyFlag = true;
    });    
    if(setNextValidated)
    headerEmptyFlag ? setNextValidated(false) : setNextValidated(true);  
  }
  
  const updateTemplateByIndex = (templatesIndex: number, elementToChange: MessageContentElementType, text: string) => {    
    const newTemplates = [...allTemplates];
    newTemplates[templatesIndex][elementToChange] = text;
    validation();  
    setDisplayedTemplates(newTemplates);
  };

  useEffect(() => {
    setDisplayedTemplates(allTemplates)
  }, [allTemplates]);
  
  return (
    <>
      <sdds-accordion>
        {
          displayedtemplates.map((content: DisturbanceMessageContentType, index) => (
            <sdds-accordion-item
              data-testid={`sdds-accordion-item-${index}`}
              header={formatCultureCode(content.cultureCode)}
              tabIndex={index}
              affix="prefix"
              key={index.toString()}
            >
              <h3>{t("TS_temp:Heading")}</h3>
              <input type="text" disabled={!editable} className="sdds-textfield-input-md" onChange={(e) => updateTemplateByIndex(index, 'header', e.target.value)} value={replacePlaceholders(content.header, replacementPattern || [])} />
              <h3>{t("TS_core:Text")}</h3>
              <textarea className="sdds-textfield-input-md" disabled={!editable} onChange={(e) => updateTemplateByIndex(index, 'body', e.target.value)} value={content.body} />
            </sdds-accordion-item>
          ))
        }
      </sdds-accordion>
    </>
  );
};

TemplateAccordion.defaultProps = {
  onChange: null,
  replacementPattern: null,
};

export default TemplateAccordion;
