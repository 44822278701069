/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { Dispatch, SetStateAction } from 'react';
import styles from './DisturbanceMessageTable.module.css';
import { CustomModal  } from '@cd/sdds-common-components-react';
import TemplateAccordion from '../TemplateAccordion';
import { t } from 'i18next';

type DisturbanceMessageContentsModalProps = {
  show: boolean;
  contents: Array<DisturbanceMessageContentType>;
  onClose: Dispatch<SetStateAction<boolean>>;
  system: string;
  type: string;
};
const headerDateTimeReplacementPattern = [    
  { placeholder: 'Invalid Date', replacement: '[N/A]  ' },
];

const DisturbanceMessageContentsModal = (props: DisturbanceMessageContentsModalProps) => {
  const { show, contents, onClose, system, type } = props;

  return (
    contents && (
      <CustomModal
        id="disturbance-message-contents-modal"
        show={show}
        title={t("TS_temp:MessageContents")}
        handleClose={() => onClose(false)}
        actions={''}
      >

        <div className={styles.messageInfo}>
          
        <div className={styles.systemName}><span className={styles.label}>{t("TS_core:System")} :</span> {system}</div>
        <div className={styles.systemType}><span className={styles.label}>{t("TS_core:Type")} :</span> {type}</div>      
        
        </div>
        <div className="sdds-row"></div>
        <TemplateAccordion allTemplates={contents} editable={false} replacementPattern={headerDateTimeReplacementPattern}/>
        
      </CustomModal>
    )
  );
};

export default DisturbanceMessageContentsModal;
