import React, {
  useState,
  useEffect,
} from 'react';
import { DateTimePicker, Dropdown, Spinner, Banner, Toast, useToast } from '@cd/sdds-common-components-react';
import TemplateAccordion from '../TemplateAccordion';
import { utcFormatFromDate } from '../../../helpers/timeFormatter';
import 'react-datepicker/dist/react-datepicker.css';
import { t } from 'i18next';

type EditMessagesStepProps = {
  disturbanceProperties: DisturbanceMessagePropertiesType;
  disturbanceTemplates: DisturbanceTemplatesType;  
  setDisturbanceTemplates: Function;
  setNextValidated:Function;
};

const EditMessagesStep = (props: EditMessagesStepProps) => {
  const { toastQueue, addToast } = useToast();
  const {
    disturbanceTemplates, disturbanceProperties, setDisturbanceTemplates, setNextValidated,
  } = props;
  const [templateHeader, setTemplateHeader] = useState(disturbanceTemplates.selectedTemplateHeader); // dropdown selection
  
  const [messageStartTime, setMessageStartTime] = useState(disturbanceProperties.messageContentStartTime || disturbanceProperties.startTime);
  const [messageEndTime, setMessageEndTime] = useState<Date>(disturbanceProperties.messageContentEndTime as Date || disturbanceProperties.endTime);
  const setTemplate = <K extends keyof DisturbanceTemplatesType>(key: K, value: DisturbanceTemplatesType[K]): void => {
    const newTemplate: DisturbanceTemplatesType = { ...disturbanceTemplates, [key]: value };
    setDisturbanceTemplates(newTemplate);
  };
  const headerDateTimeReplacementPattern = [
    { placeholder: utcFormatFromDate(disturbanceProperties.messageContentStartTime as Date), replacement: utcFormatFromDate(messageStartTime) },
    { placeholder: utcFormatFromDate(disturbanceProperties.messageContentEndTime as Date), replacement: utcFormatFromDate(messageEndTime as Date) },
    { placeholder: utcFormatFromDate(disturbanceProperties.startTime), replacement: utcFormatFromDate(messageStartTime) },
    { placeholder: utcFormatFromDate(disturbanceProperties.endTime), replacement: utcFormatFromDate(messageEndTime as Date) },
    { placeholder: '[start time]', replacement: utcFormatFromDate(messageStartTime || disturbanceProperties.startTime) },
    { placeholder: '[end time]', replacement: (messageEndTime || disturbanceProperties.endTime) ? (utcFormatFromDate(messageEndTime || disturbanceProperties.endTime)) : '[end time]' },
    { placeholder: '{0}', replacement: utcFormatFromDate(messageStartTime || disturbanceProperties.startTime) },
    { placeholder: '{1}', replacement: (messageEndTime || disturbanceProperties.endTime) ? (utcFormatFromDate(messageEndTime || disturbanceProperties.endTime)) : '[end time]' },
  ];
  const [show,setShow] = useState(false);

  /*Save values picked for message-content start and end times*/
  useEffect(() => {  
    if(messageEndTime && messageStartTime > messageEndTime){
      setMessageEndTime(messageStartTime);
      addToast({ text: t('Please select an end time later than start time.'), type: 'error' });
    }          
    disturbanceProperties.messageContentStartTime = messageStartTime;
    disturbanceProperties.messageContentEndTime = messageEndTime || null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageStartTime, messageEndTime])
  
  useEffect(() => {
    setTemplate('selectedTemplateHeader', templateHeader);  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateHeader])  

  useEffect(() => {          
    setShow(disturbanceTemplates.templatesError.loading)
  }, [disturbanceTemplates]) 
 
  const TemplateContentDatePickers = (componentProps: { header: string }): JSX.Element | null => {
    const { header } = componentProps;
    const requireDynamicTimes = header.includes('[start time]');

    if (!requireDynamicTimes) return null;

    return (
      <>
        <div className="sdds-row mt-16">
          <div className="sdds-col">
            <p className='label-size'>{t("TS_temp:LabelDateTimeRange")}</p>
          </div>
        </div>
        <div className="sdds-row ">
          <div className="sdds-col">
             {/* TODO We shold change with new tegel Datetime Picker */}
            <DateTimePicker
              time
              selectedDate={messageStartTime}
              onChange={(date: Date) => setMessageStartTime(date)}
              maxDate={new Date(Date.now() + 29999222225)}// Adding 1year as a quick fix for selecting future date. 
            />
            <div className='utc-conversion'>{t("TS_temp:LabelStartTime")} : {utcFormatFromDate(messageStartTime) + ' (UTC)'}</div>
          </div>
          <div className="sdds-col">
            {/* TODO We shold change with new tegel Datetime Picker */}
            <DateTimePicker
              time
              selectedDate={messageEndTime? messageEndTime : messageStartTime}
              onChange={(date: Date) => setMessageEndTime(date)}
              minDate={messageStartTime}
              maxDate={new Date(Date.now() + 29999222225)}// Adding 1year as a quick fix for selecting future date. 
            />
            <div className='utc-conversion'>{t("TS_temp:LabelEndTime")} : {utcFormatFromDate(messageEndTime? messageEndTime : messageStartTime) + ' (UTC)'}</div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="edit-message-container add-message-modal-screen"> 
      {
        (disturbanceTemplates.templateHeaderError.error || disturbanceTemplates.templatesError.error) && (
          <div className="sdds-row">
            <div className="sdds-col">
              <Banner text={disturbanceTemplates.templateHeaderError.errorMessage || disturbanceTemplates.templatesError.errorMessage || 'Unable to connect with backend'} type="error" />
            </div>
          </div>
        )
      }
      {
        (!disturbanceTemplates.templateHeaderError.error && !disturbanceTemplates.templatesError.error) && (
          <>
          <div className='sdds-row label-size'>{t("TS_temp:MessageTemplate")}</div>
            <div className="sdds-row sticky-position drop">
              <div className="sdds-col mod-col">
                {(disturbanceTemplates.templateHeaders.length > 0) && (
                  <Dropdown data={disturbanceTemplates.templateHeaders} placeholder={t("TS_temp:placeholderSelectTemplate")} selectedValue={templateHeader} setSelectedValue={setTemplateHeader} />
                )}
              </div>
            </div>
            <TemplateContentDatePickers header={templateHeader} />            
            {(show) && (<Spinner/>)} 
            {
              disturbanceTemplates.templates.length > 0 && !show && (
                <div className="sdds-row">
                  <div className="sdds-col mod-col">
                    <TemplateAccordion
                      allTemplates={disturbanceTemplates.templates}
                      editable
                      replacementPattern={headerDateTimeReplacementPattern}
                      setNextValidated = {setNextValidated}
                    />
                  </div>
                </div>
              )
            }
          </>
        )
      }
       <Toast toastQueue={toastQueue} />
    </div>
  );
};

export default EditMessagesStep;

