import React from 'react';
import ProfileDropdownItem from './ProfileDropdownItem';
import './ProfileDropdown.css';
import '../../styles/sdds-filler.css';
import { useTranslation } from "react-i18next";

type ProfileDropdownProps = {
  children: any,
  profileInfo: ProfileInformationType,
  profileDropdownActiveState: Boolean,
  setProfileDropdownActiveState: Function,
};

const ProfileDropdown = ({ children, profileInfo, profileDropdownActiveState, setProfileDropdownActiveState }: ProfileDropdownProps) => {

  const hideOtherDropdowns = () => {
    setProfileDropdownActiveState(!profileDropdownActiveState)
  }
  const { t } = useTranslation();
  return (

    <li className="sdds-nav__item sdds-nav__avatar" >
      <button className="sdds-nav__avatar-btn" onClick={() => hideOtherDropdowns()} title={t("TS_temp:LoggedInUser")}>
        <img className="sdds-nav__avatar-img" src={"https://www.svgrepo.com/show/170303/avatar.svg"} alt=" "></img>
        <div className="sdds-nav__avatar-info sdds-nav__avatar-info--mobile">
          <p className="sdds-nav__avatar-title">{profileInfo.firstName + ' ' + profileInfo.lastName + '(' + profileInfo.role + ')'}</p>
        </div>
      </button>

      <ul className="sdds-nav__avatar-menu">
        <li className="sdds-nav__avatar-item sdds-nav__avatar-item--large">
          <div className="sdds-nav__avatar-info">
            <p className="sdds-nav__avatar-title">{profileInfo.firstName + ' ' + profileInfo.lastName}</p>
            <p className="sdds-nav__avatar-subtitle">({profileInfo.role})</p>
          </div>
        </li>
        {children}
      </ul>
    </li>
  );
};

export { ProfileDropdown, ProfileDropdownItem };
