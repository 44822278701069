const replacePlaceholders = (text: string, mutations: { placeholder: string, replacement: string }[]) : string => {
  let mutatedText = text;
  mutations.forEach(m => {
    mutatedText = mutatedText.replace(m.placeholder, m.replacement);
  });
  return mutatedText;
};

export default replacePlaceholders;

export {
  replacePlaceholders,
};
