/* eslint-disable quote-props */
type LanguageCodeMapperType = {
  [key: string]: string
};

const languageCodeMapper: LanguageCodeMapperType = {
  'bg-BG': 'български',
  'cs-CZ': 'Czech',
  'da-DK': 'Dansk',
  'de-DE': 'Deutsch',
  'en-GB': 'English',
  'es-ES': 'Español',
  'et-EE': 'Eesti',
  'fi-FI': 'Suomi',
  'fr-FR': 'Française',
  'hu-HU': 'Magyar',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'lt-LT': 'Lietuvių',
  'nb-NO': 'Norsk',
  'nl-NL': 'Nederlands',
  'pl-PL': 'Polski',
  'pt-BR': 'Português (Brasil)',
  'pt-PT': 'Português (Europeu)',
  'ro-RO': 'Română',
  'ru-RU': 'Русский',
  'sr-RS': 'Srpski',
  'sv-SE': 'Svenska',
  'th-TH': 'ภาษาไทย',
  'tr-TR': 'Türkçe',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文'
};

export default languageCodeMapper;

export {
  languageCodeMapper
};
