/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../authConfig';

export const LoginLogoutToolbarItem = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  return (
    <>
      <AuthenticatedTemplate>
        <a role="button" onClick={() => instance.logout()}>{ t("TS_core:Logout") }</a>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <a role="button" onClick={() => instance.loginRedirect(loginRequest)}>{ t("TS_temp:Login") }</a>
      </UnauthenticatedTemplate>
    </>
  );
};

export default LoginLogoutToolbarItem;
