import React from 'react';
import { usePagination, DOTS } from './usePagination';
import styles from './pagination.module.css'

import Left from '../../assets/left.svg'
import Right from '../../assets/right.svg'

type PaginationProps = {
    onPageChange: any,
    totalCount: number,
    siblingCount?: number,
    currentPage: number,
    pageSize: number
};
const Pagination = (props: PaginationProps) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange: any = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <div className={styles.paginationWrapper}>
            <button
                className={currentPage === 1 ? styles.page_disabled : styles.pagination_number }
                onClick={onPrevious}
                disabled={currentPage === 1}
            >
                <img className={styles.arrow} src={Left} alt="left"/>
            </button>
            {paginationRange.map((pageNumber: any, index: any) => {
                if (pageNumber === DOTS) {
                    return <button className={styles.pagination_number} key={index}>&#8230;</button>;
                }
                return (
                    <button
                        className={currentPage === pageNumber ? styles.page_active : styles.pagination_number}
                        onClick={() => onPageChange(pageNumber)}
                        key={index}
                    >
                        {pageNumber}
                    </button>
                );
            })}
            <button
                className={currentPage === lastPage ? styles.page_disabled : styles.pagination_number }
                onClick={onNext}
                disabled={currentPage === lastPage}
            >
                 <img className={styles.arrow} src={Right} alt="Right"/>
            </button>
        </div>
    );
};

export default Pagination;
