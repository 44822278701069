import React, { useState, useEffect } from 'react'


import { Dropdown, CustomModal,Toast, useToast  } from '@cd/sdds-common-components-react';

import { languageCodeMapper } from "../../helpers/languageCodes";
import styles from './UserSettings.module.css';
import { useTranslation } from 'react-i18next';

import { updateUserSetting, getRoles, changeUserRole } from '../../adapters/UserAdapter';


type UserSettingsPropsType = {
    isOpen: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    userDetails: any;
    setLoggedInProfileInfo: Function;
};
type UserSetting = {
    language: any,
    unit: string
}
type UserSettingsDetailsType = {
    firstName: string,
    lastName: string,
    email: string,
    role: any,
    settings: UserSetting,
};

const UserSettings = (props: UserSettingsPropsType) => {
    const lan = languageCodeMapper
    const languageCode = Object.values(lan)

    const getLanguageKey = (list: any, value: string) => {
        return Object.keys(list).find(key => list[key] === value);
    }

    const { isOpen, userDetails, onClose, setLoggedInProfileInfo } = props;
    const [open, setOpen] = useState<boolean>(isOpen)
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true)

    const { toastQueue, addToast } = useToast();

    const [settings, setSettings] = useState<UserSettingsDetailsType>({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        role: userDetails.role,
        settings: {
            language: getLanguageKey(lan, userDetails.settings.language),
            unit: userDetails.settings.unit,
        },
    });
    const [language, setLanguage] = useState(lan[userDetails.settings.language]);
    const [roles, setRoles] = useState(userDetails.role);
    const [unit, setUnit] = useState(userDetails.settings.unit);

    const [roleList, setRoleList] = useState([]);

    const { t } = useTranslation();

    let newLoggedInProfileInfo = { ...userDetails };
    const saveChanges = () => {
        setLoggedInProfileInfo(newLoggedInProfileInfo);
        onClose();
    }

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen]);

    useEffect(() => {
        getRoles().then((response: any) => {
            setRoleList(response.data)
        })
    }, []);

    useEffect(() => {        
        setSettings({
            ...settings, role: roles, settings: {
                language: getLanguageKey(lan, language),
                unit: unit
            }
        })
        if (unit !== userDetails.settings.unit ||
            language !== lan[userDetails.settings.language] ||
            roles !== userDetails.role) {
            setSaveDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, roles, unit, lan]);

    const handleSubmit = async () => {

        if (language !== lan[userDetails.settings.language]) {
            await updateUserSetting('language', settings.settings.language).then((response: any) => {
                newLoggedInProfileInfo.settings.language = getLanguageKey(lan, language);
                addToast({ text: t('Language has been updated'), type: 'success' });
                const timeout = setTimeout(() => {
                    saveChanges()
                }, 2200);
                return () => clearTimeout(timeout);
            })
                .catch((error: Error) => {
                    addToast({ text: t('Language update failed'), type: 'error' });
                })
        }
        if (unit !== userDetails.settings.unit) {
            await updateUserSetting('unit', settings.settings.unit).then((response: any) => {
                newLoggedInProfileInfo.settings.unit = unit;
                addToast({ text: t('Units has been updated'), type: 'success' });
                const timeout = setTimeout(() => {
                    saveChanges()
                }, 2200);
                return () => clearTimeout(timeout);
            })
                .catch((error: Error) => {
                    addToast({ text: t('Units update failed'), type: 'error' });
                })
        }
        if (roles !== userDetails.role) {
            await changeUserRole(settings.role).then((response: any) => {
                newLoggedInProfileInfo.role = roles;
                addToast({ text: t('User role has been updated'), type: 'success' });
                const timeout = setTimeout(() => {
                    saveChanges()
                }, 2200);
                return () => clearTimeout(timeout);
            })
                .catch((error: Error) => {
                    addToast({ text: t('User role update failed'), type: 'error' });
                })
        }
    }

    return (
        <CustomModal
            id="user-settings-modal"
            show={open}
            title="User Settings"
            handleClose={onClose}
            actions={(
                <>
                    <button disabled={saveDisabled} type="button" className="sdds-btn sdds-btn-sm sdds-btn-primary bttn" onClick={handleSubmit}>{t("TS_core:Save")}</button>
                    <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn" onClick={onClose} >{t('Cancel')}</button>
                </>
            )}
        >
            <div className={styles.userInformation}>
                <div className={styles.infoTitle}> {t('userInformation')}</div>
                <div className={styles.informations}>
                    <div className={styles.details}>
                        <div className={styles.name}>
                            <p>{t('Name')} : </p>
                            <p>{t('Email Address')} : </p>
                        </div>
                        <div className={styles.value}>
                            <p> {settings.firstName} {settings.lastName}</p>
                            <p> {settings.email} </p>
                        </div>
                    </div>
                    <div className={styles.details}>
                        <div className={styles.name}>
                            <p>{t('Role')} : </p>
                        </div>
                        <div className={styles.value}>
                            <p> {settings.role} </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.Settings}>
                <div className={styles.infoTitle}>{t('Settings')} </div>
                <div className={styles.informations}>
                    <div className={`${styles.language} dropdowns`}>
                        <p>{t('Language')}</p>

                        <Dropdown data={languageCode.sort()} placeholder="Language" selectedValue={language} setSelectedValue={setLanguage} />
                    </div>
                    <div className={`${styles.role} dropdowns`}>
                        <p>{t('Role')} </p>
                        <Dropdown data={roleList} placeholder="Role" selectedValue={roles} setSelectedValue={setRoles} />
                    </div>

                </div>
                <div>
                    <div className={styles.unit}>
                        <p>{t('Units')} </p>
                        <div className={styles.radioButtons}>
                            <div>
                                <input type="radio" value="Metric" name="Metric" checked={unit === 'Metric'} onChange={(e) => setUnit(e.target.value)} className={styles.radio} />
                                {t('Metric units')}
                            </div>
                            <div>
                                <input type="radio" value="Imperial" name="Imperial" checked={unit === 'Imperial'} onChange={(e) => setUnit(e.target.value)} className={styles.radio} />
                                {t('Imperial units')}
                            </div>
                        </div>
                    </div>
                </div>
                <Toast toastQueue={toastQueue} />

            </div>
        </CustomModal>
    )
}

export default UserSettings;