/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { ProfileDropdown, ProfileDropdownItem } from "../ProfileDropdown";
import { LoginLogoutToolbarItem } from "../Navbar/LoginLogoutToolbarItem";
import { useTranslation } from "react-i18next";
import UserSettings from "../UserSettings";
import "./styles.css";
import { t } from "i18next";

type ProfileDropdownItemType = {
  label: string,
  url?: string,
  element?: JSX.Element
};

const profileDropdownItems = [
  { label: t("Logout"), element: <LoginLogoutToolbarItem /> },
  // { label: "Send feedback", url: " " },
];


type ScaniaHeaderProps = {
  children: any,
  profileInfo: ProfileInformationType,
  setLoggedInProfileInfo: Function,
  currentApp: string,
  "data-testid"?: string,
};

const Header = ({
  children,
  profileInfo,
  setLoggedInProfileInfo,
  currentApp,
  "data-testid": dataTestId,
}: ScaniaHeaderProps) => {

  const [appLauncherActiveState, setAppLauncherActiveState] = useState<boolean>(false);
  const [profileDropdownActiveState, setProfileDropdownActiveState] = useState<boolean>(false);
  const { t } = useTranslation();
  const [settingsActiveState, setSettingsActiveState] = useState<boolean>(false);
  // TO DO
  //dynamically get URLs based on environment 
  // CSTT1.0, CSTT 2.0 apps + external apps (keep connecting words in app names all in lowercase eg. "and")

  useEffect(() => {
    if (settingsActiveState)
      setProfileDropdownActiveState(false);
    setAppLauncherActiveState(false);
  }, [settingsActiveState]);

  useEffect(() => {
    if (appLauncherActiveState)
      setProfileDropdownActiveState(!appLauncherActiveState);
  }, [appLauncherActiveState]);

  useEffect(() => {
    if (profileDropdownActiveState)
      setAppLauncherActiveState(!profileDropdownActiveState);
  }, [profileDropdownActiveState]);

  return (<>
    <nav className={`sdds-nav 
      ${appLauncherActiveState ? "sdds-nav__app-launcher--opened" : ''} 
      ${profileDropdownActiveState ? "sdds-nav__avatar--opened" : ''} 
      ${settingsActiveState ? "sdds-nav__item-core--opened" : ''}
    `}>
      <div className="sdds-nav__left">
        <div className="sdds-nav__overlay"></div>
        <button className="sdds-nav__mob-menu-btn" >
          <div id="sdds-nav__mob-menu-icon">
            <span className="sdds-nav__mob-menu-icon-line" id="sdds-nav__mob-menu-icon-line-1"></span>
            <span className="sdds-nav__mob-menu-icon-line" id="sdds-nav__mob-menu-icon-line-2"></span>
            <span className="sdds-nav__mob-menu-icon-line" id="sdds-nav__mob-menu-icon-line-3"></span>
          </div>
        </button>
        <div className="sdds-nav__app-name">{currentApp}</div>
      </div>
      <div className="sdds-nav__center">
      </div>
      <ul className="sdds-nav__toolbar-menu">
        <li className="sdds-nav__item-core" >
          <a className="user-settings sdds-nav__item-core-text" href="#" onClick={() => setSettingsActiveState(settingsActiveState => !settingsActiveState)} title={t("TS_temp:UserSettings")}>
            <img src='../../../../assets/icons/Settings.svg' alt={t('Settings')} />
          </a>
        </li>
        <ProfileDropdown profileInfo={profileInfo} profileDropdownActiveState={profileDropdownActiveState} setProfileDropdownActiveState={setProfileDropdownActiveState}>
          {profileDropdownItems && profileDropdownItems.map((item: ProfileDropdownItemType, index) => {
            if (item.element)
              return <ProfileDropdownItem text={item.label} key={`${item.label}+ index`} element={item.element} />
            else
              return <ProfileDropdownItem text={item.label} key={`${item.label}+ index`} url={item.url} />
          })}
        </ProfileDropdown>
      </ul>
      <div className="sdds-nav__right">
        <a className="sdds-nav__item sdds-nav__app-logo" id="scania-logo" href={`https://cstt${process.env.REACT_APP_CSTT_DOMAIN}/`} title={t("TS_temp:CsttHome")}>&nbsp;</a>
      </div>
    </nav>

    <UserSettings
      isOpen={settingsActiveState}
      onClose={() => setSettingsActiveState(false)}
      userDetails={profileInfo}
      setLoggedInProfileInfo={setLoggedInProfileInfo}
    />
  </>
  )
};

Header.defaultProps = {
  "data-testid": null,
  children: null,
  profileInfo: null,
  currentApp: null
};

export default Header;
