import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCultureCode } from '../../../helpers/cultureCodeMapper';
import { utcFormatFromDate } from '../../../helpers/timeFormatter';
import { replacePlaceholders } from '../../../helpers/stringMutations';
import { t } from 'i18next';

type ReviewStepProps = {
  disturbanceTemplates: DisturbanceTemplatesType;
  disturbanceProperties: DisturbanceMessagePropertiesType;
};

const ReviewStep = (props: ReviewStepProps) => {
  const { disturbanceProperties, disturbanceTemplates } = props;
  const { i18n } = useTranslation();

  const TemplateCard = () : JSX.Element | null => {
    const cultureCode = i18n.language;
    const result = disturbanceTemplates.templates.find(x => x.cultureCode === cultureCode);
    if (!result) return null;

    const template = { ...result };
    if (template.header.includes('{0}')) {
      const dp = disturbanceProperties;
      template.header = replacePlaceholders(template.header, [
        { placeholder: '{0}', replacement: utcFormatFromDate((dp.messageContentStartTime || dp.startTime) as Date) },
        { placeholder: '{1}', replacement: (dp.messageContentEndTime || dp.endTime)? utcFormatFromDate((dp.messageContentEndTime || dp.endTime) as Date) : '[N/A]  ' },
      ]);
    }

    return (
      <div className="sdds-card ">
        <div className="sdds-card-header">
          <h4 className="sdds-card-headline">
            {t("TS_temp:Contents")}
            <span> </span>
            &#40;
            { formatCultureCode(cultureCode) }
            &#41;
          </h4>
        </div>
        <div className="sdds-card-body">
          <h5>{t("TS_temp:Heading")}</h5>
          <p>{ template.header }</p>
          <h5>{t("TS_core:Text")}</h5>
          <p>{ template.body }</p>
        </div>
      </div>
    );
  };

  return (
    <div className="review-message-container">
      <div className="sdds-row mt-16">
        <div className="sdds-col mod-col">
          <h6>{t("TS_core:SystemName")}</h6>
          <p>{ disturbanceProperties.system }</p>
        </div>
        <div className="sdds-col">
          <h6>{t("TS_core:Type")}</h6>
          <p>{disturbanceProperties.disturbanceType}</p>
        </div>
      </div>     
      <div className="sdds-row">
        <div className="sdds-col mod-col">
          <h6>{t("TS_core:StartTime")}</h6>
          <p>{ utcFormatFromDate(disturbanceProperties.startTime) } (UTC)</p>
        </div>
        <div className="sdds-col">
          <h6>{t("TS_core:EndTime")}</h6>
          <p>{ disturbanceProperties.endTime ? utcFormatFromDate(disturbanceProperties.endTime) +' (UTC)' : '-' } </p>
        </div>
      </div>
      <div className="sdds-row">
        <div className="sdds-col mod-col">
          <TemplateCard />
        </div>
      </div>
    </div>
  );
};

export default ReviewStep;
