import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect, 
} from 'react';
import { CustomModal  } from '@cd/sdds-common-components-react';
import SetPropertiesStep from './SetPropertiesStep';
import EditMessagesStep from './EditMessagesStep';
import ReviewStep from './ReviewStep';
import Steppers from '../../../components/Steppers/Steppers';
import { utcFormatFromDate } from '../../../helpers/timeFormatter';
import { replacePlaceholders } from '../../../helpers/stringMutations';
import 'react-datepicker/dist/react-datepicker.css';
import './AddDisturbanceMessageModal.css';
import { createDisturbanceMessage, getDisturbanceTypes, getSystems, getDisturbanceTemplateHeaders, getDisturbanceTemplateByKey } from '../../../adapters/DisturbanceMessageAdapter';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

type DisturbanceMessageActionModalProps = {
  show: boolean;
  onAdd: () => void;
  onClose: Dispatch<SetStateAction<boolean>>;
};

const DisturbanceMessageActionModal = (props: DisturbanceMessageActionModalProps) => {
  const { show, onClose, onAdd } = props;
  const { i18n } = useTranslation();
  const [templateHeaderData, setTemplateHeaderData] = useState<DisturbanceTemplateHeaderType[]>();
  const [disturbanceProperties, setDisturbanceProperties] = useState<DisturbanceMessagePropertiesType>({
    system: null,
    disturbanceType: null,
    startTime: new Date(),
    endTime: null,
    messageContentStartTime: null,
    messageContentEndTime: null,
    allSystems: [],
    allTypes: [],
    systemsError: {
      loading: true,
      error: false
    },
    typesError: {
      loading: true,
      error: false
    }
  });
  const [disturbanceTemplates, setDisturbanceTemplates] = useState<DisturbanceTemplatesType>({
    templateHeaders: [],
    templateHeaderError: {
      loading: true,
      error: false
    },
    templates: [],
    templatesError: {
      loading: false,
      error: false
    },
    selectedTemplateHeader: ''

  });
  const updateDisturbanceProps = (props: DisturbanceMessagePropertiesType) => {
    setDisturbanceProperties(props);
  }
  const updateDisturbanceTemplates = (templates: DisturbanceTemplatesType) => {
    setDisturbanceTemplates(templates);
  }
  const [nextValidated, setNextValidated] = useState<boolean>(false);
  const viewStates: ViewStatesType = {
    properties: {
      current: 'properties', next: 'edit', previous: null,
    },
    edit: {
      current: 'edit', next: 'review', previous: 'properties',
    },
    review: {
      current: 'review', next: null, previous: 'edit', submit: true,
    },
  };
  const [viewState, setViewState] = useState<ViewStateType>(viewStates.properties as ViewStateType);
  const headerDateTimeReplacementPattern = [
    { placeholder: '{0}', replacement: utcFormatFromDate(disturbanceProperties.messageContentStartTime || disturbanceProperties.startTime) },
    { placeholder: '{1}', replacement: utcFormatFromDate((disturbanceProperties.messageContentEndTime || disturbanceProperties.endTime) as Date) },
  ];

  const switchState = (newState: ViewType | null): void => {
    if (!newState) return;
    setViewState(viewStates[newState]);
  };

  const resetState = (): void => {
    setDisturbanceProperties({
      system: null,
      disturbanceType: null,
      startTime: new Date(),
      endTime: null,
      messageContentStartTime: null,
      messageContentEndTime: null,
      allSystems: disturbanceProperties.allSystems,
      allTypes: disturbanceProperties.allTypes,
      systemsError: {
        loading: false,
        error: false
      },
      typesError: {
        loading: false,
        error: false
      }
    });
    setDisturbanceTemplates({
      templateHeaders: disturbanceTemplates.templateHeaders,
      templateHeaderError: {
        loading: false,
        error: false
      },
      templates: [],
      templatesError: {
        loading: false,
        error: false
      },
      selectedTemplateHeader: ''
    });
    setNextValidated(false);
    setViewState(viewStates.properties as ViewStateType);
  };

  const handleClose = (): void => {
    resetState();
    onClose(false);
  };

  const handleSubmit = (): void => {
    const newDisturbanceMessage: NewDisturbanceMessageType = {
      systemName: disturbanceProperties.system as string,
      type: disturbanceProperties.disturbanceType as string,
      startTime: disturbanceProperties.startTime.toISOString(),
      endTime: disturbanceProperties.endTime?.toISOString() || null,
      messageContents: disturbanceTemplates.templates.map(t => ({
        ...t,
        header: replacePlaceholders(t.header, headerDateTimeReplacementPattern),
      })),
      messageContentStartTime: disturbanceProperties.messageContentStartTime?.toISOString() || null,
      messageContentEndTime: disturbanceProperties.messageContentEndTime?.toISOString() || null,
    };
    createDisturbanceMessage(newDisturbanceMessage)
      .then(() => {
        resetState();
        onAdd();
        onClose(false);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  };
  
  useEffect(() => {
    switch (viewState.current) {
      case 'properties':
        setNextValidated(disturbanceProperties.system !== null && disturbanceProperties.disturbanceType != null);
        break;
      case 'edit':
        setNextValidated(disturbanceTemplates.templates.length > 0);
        break;
      default:
    }
  }, [disturbanceProperties, disturbanceTemplates, viewState]);

  useEffect(() => {
    getSystems()
      .then(response => {            
        disturbanceProperties.allSystems = response.data;
        disturbanceProperties.systemsError = { loading: false, error: false };        
        setDisturbanceProperties(disturbanceProperties);        
      })
      .catch(error => {
        console.error(error);
        disturbanceProperties.systemsError = { loading: false, error: true, errorMessage:error };   
        setDisturbanceProperties(disturbanceProperties);     
      });
    getDisturbanceTypes()
      .then(response => {
        disturbanceProperties.allTypes = response.data;        
        disturbanceProperties.typesError = { loading: false, error: false };        
        setDisturbanceProperties(disturbanceProperties);
      })
      .catch(error => {
        console.error(error);
        disturbanceProperties.typesError = { loading: false, error: true, errorMessage:error };     
        setDisturbanceProperties(disturbanceProperties);   
      });
    getDisturbanceTemplateHeaders(i18n.language)
      .then(response => {
        disturbanceTemplates.templateHeaderError = { loading: false, error: false };       
        setTemplateHeaderData(response.data)
      })
      .catch(error => {
        console.error(error);        
        disturbanceTemplates.templateHeaderError = { loading: false, error: true, errorMessage:error };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const GetHeaderDataForDisplay = (templateHeaders: Array<DisturbanceTemplateHeaderType>) => {
      const filteredHeaders = templateHeaders.filter(header => header.systems.includes(disturbanceProperties.system as string));
      const headerDataForDisplay = filteredHeaders.map(
        response => {//replace all placeholders with coherent text in headers
          response.header = response.header.replace('{0}', '[start time]');
          response.header = response.header.replace('{1}', '[end time]');
          return response.header;
        });
      return headerDataForDisplay;
    }
    disturbanceTemplates.templateHeaders = GetHeaderDataForDisplay(templateHeaderData || []);
    setDisturbanceTemplates(disturbanceTemplates);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disturbanceProperties.system, disturbanceProperties.endTime, disturbanceProperties.startTime])

  useEffect(() => { // load templates based on selected template header
    if (disturbanceTemplates.selectedTemplateHeader === '') return;
     disturbanceTemplates.templatesError= { loading: true, error: false };
     setDisturbanceTemplates(disturbanceTemplates) 
    const setTemplate = <K extends keyof DisturbanceTemplatesType>(key: K, value: DisturbanceTemplatesType[K]): void => {
      const newTemplate: DisturbanceTemplatesType = { ...disturbanceTemplates, [key]: value };
      setDisturbanceTemplates(newTemplate);
    };
    

    const keyFromHeader = (templateHeaderData?.filter((header: { header: any; }) => header.header === disturbanceTemplates.selectedTemplateHeader)[0]?.key)?.toString() || '';
    if (keyFromHeader) {
      getDisturbanceTemplateByKey(keyFromHeader)
        .then(response => {          
          disturbanceTemplates.templatesError={ loading: false, error: false };
          setDisturbanceTemplates(disturbanceTemplates)     
          setTemplate('templates', response.data)
        })
        .catch(error => {
          console.error(error);
          disturbanceTemplates.templatesError={ loading: false, error: true, errorMessage:error };
          setDisturbanceTemplates(disturbanceTemplates)           
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disturbanceTemplates.selectedTemplateHeader]);

  return (
    <CustomModal
      id="new-disturbance-message-modal"
      show={show}
      title={t("TS_temp:NewDisturbanceMessage")}
      handleClose={() => handleClose()}
      actions={(
        <>
          {viewState.submit && (
            <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-primary bttn" onClick={() => handleSubmit()}>{t("TS_core:Submit")}</button>
          )}         
          {viewState.next && (
            <button type="button" disabled={!nextValidated} className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn" onClick={() => switchState(viewState.next)}>{t("TS_temp:Next")}</button>
          )}
          {viewState.previous && (
            <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-secondary mr-8 bttn" onClick={() => switchState(viewState.previous)}>{t("TS_temp:Previous")}</button>
          )}         
        </>
      )}
    >
      <Steppers step={viewState.current}/>
      {
        viewState.current === 'properties' && (
          <SetPropertiesStep disturbanceProperties={disturbanceProperties} setDisturbanceProperties={updateDisturbanceProps} />
        )
      }
      {
        viewState.current === 'edit' && (
          <EditMessagesStep disturbanceProperties={disturbanceProperties}            
            disturbanceTemplates={disturbanceTemplates}
            setDisturbanceTemplates={updateDisturbanceTemplates}           
            setNextValidated={setNextValidated} />
        )
      }
      {
        viewState.current === 'review' && (
          <ReviewStep disturbanceProperties={disturbanceProperties} disturbanceTemplates={disturbanceTemplates} />
        )
      }
    </CustomModal>
  );
};

export default DisturbanceMessageActionModal;
