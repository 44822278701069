/* eslint-disable quote-props */
type CultureCodeMapperType = {
  [key: string]: string
};

const cultureCodeMapper: CultureCodeMapperType = {
  'af-ZA': 'Afrikaans',
  'ar-AE': 'Arabic',
  'ar-BH': 'Arabic',
  'ar-DZ': 'Arabic',
  'ar-EG': 'Arabic',
  'ar-IQ': 'Arabic',
  'ar-JO': 'Arabic',
  'ar-KW': 'Arabic',
  'ar-LB': 'Arabic',
  'ar-LY': 'Arabic',
  'ar-MA': 'Arabic',
  'ar-OM': 'Arabic',
  'ar-QA': 'Arabic',
  'ar-SA': 'Arabic',
  'ar-SY': 'Arabic',
  'ar-TN': 'Arabic',
  'ar-YE': 'Arabic',
  'az-AZ': 'Azeri',
  'az-Cyrl-AZ': 'Azeri',
  'be-BY': 'Belarusian',
  'bg-BG': 'Bulgarian',
  'bs-BA': 'Bosnian',
  'ca-ES': 'Catalan',
  'cs-CZ': 'Czech',
  'cy-GB': 'Welsh',
  'da-DK': 'Danish',
  'de-AT': 'German',
  'de-CH': 'German',
  'de-DE': 'German',
  'de-LI': 'German',
  'de-LU': 'German',
  'dv-MV': 'Divehi',
  'el-GR': 'Greek',
  'en-AU': 'English',
  'en-BZ': 'English',
  'en-CA': 'English',
  'en-CB': 'English',
  'en-GB': 'English',
  'en-IE': 'English',
  'en-JM': 'English',
  'en-NZ': 'English',
  'en-PH': 'English',
  'en-TT': 'English',
  'en-US': 'English',
  'en-ZA': 'English',
  'en-ZW': 'English',
  'es-AR': 'Spanish',
  'es-BO': 'Spanish',
  'es-CL': 'Spanish',
  'es-CO': 'Spanish',
  'es-CR': 'Spanish',
  'es-DO': 'Spanish',
  'es-EC': 'Spanish',
  'es-ES': 'Spanish',
  'es-GT': 'Spanish',
  'es-HN': 'Spanish',
  'es-MX': 'Spanish',
  'es-NI': 'Spanish',
  'es-PA': 'Spanish',
  'es-PE': 'Spanish',
  'es-PR': 'Spanish',
  'es-PY': 'Spanish',
  'es-SV': 'Spanish',
  'es-UY': 'Spanish',
  'es-VE': 'Spanish',
  'et-EE': 'Estonian',
  'eu-ES': 'Basque',
  'fa-IR': 'Farsi',
  'fi-FI': 'Finnish',
  'fo-FO': 'Faroese',
  'fr-BE': 'French',
  'fr-CA': 'French',
  'fr-CH': 'French',
  'fr-FR': 'French',
  'fr-LU': 'French',
  'fr-MC': 'French',
  'gl-ES': 'Galician',
  'gu-IN': 'Gujarati',
  'he-IL': 'Hebrew',
  'hi-IN': 'Hindi',
  'hr-BA': 'Croatian',
  'hr-HR': 'Croatian',
  'hu-HU': 'Hungarian',
  'hy-AM': 'Armenian',
  'id-ID': 'Indonesian',
  'is-IS': 'Icelandic',
  'it-CH': 'Italian',
  'it-IT': 'Italian',
  'ja-JP': 'Japanese',
  'ka-GE': 'Georgian',
  'kk-KZ': 'Kazakh',
  'kn-IN': 'Kannada',
  'ko-KR': 'Korean',
  'kok-IN': 'Konkani',
  'ky-KG': 'Kyrgyz',
  'lt-LT': 'Lithuanian',
  'lv-LV': 'Latvian',
  'mi-NZ': 'Maori',
  'mk': 'FYRO Macedonian',
  'mk-MK': 'FYRO Macedonian',
  'mn-MN': 'Mongolian',
  'mr-IN': 'Marathi',
  'ms-BN': 'Malay',
  'ms-MY': 'Malay',
  'mt-MT': 'Maltese',
  'nb-NO': 'Norwegian',
  'nl-BE': 'Dutch',
  'nl-NL': 'Dutch',
  'nn-NO': 'Norwegian',
  'ns-ZA': 'Northern Sotho',
  'pa-IN': 'Punjabi',
  'pl-PL': 'Polish',
  'ps-AR': 'Pashto',
  'pt-BR': 'Brazilian Portuguese',
  'pt-PT': 'Portuguese',
  'qu-BO': 'Quechua',
  'qu-EC': 'Quechua',
  'qu-PE': 'Quechua',
  'ro-RO': 'Romanian',
  'ru-RU': 'Russian',
  'sa-IN': 'Sanskrit',
  'se-FI': 'Sami',
  'se-NO': 'Sami',
  'se-SE': 'Sami',
  'sk-SK': 'Slovak',
  'sl-SI': 'Slovenian',
  'sq-AL': 'Albanian',
  'sr-BA': 'Serbian',
  'sr-Cyrl-BA': 'Serbian',
  'sr-SP': 'Serbian',
  'sr-Cyrl-SP': 'Serbian',
  'sv-FI': 'Swedish',
  'sv-SE': 'Swedish',
  'sw-KE': 'Swahili',
  'syr-SY': 'Syriac',
  'ta-IN': 'Tamil',
  'te-IN': 'Telugu',
  'th-TH': 'Thai',
  'tl-PH': 'Tagalog',
  'tn-ZA': 'Tswana',
  'tr-TR': 'Turkish',
  'tt-RU': 'Tatar',
  'uk-UA': 'Ukrainian',
  'ur-PK': 'Urdu',
  'uz-UZ': 'Uzbek',
  'uz-Cyrl-UZ': 'Uzbek',
  'vi-VN': 'Vietnamese',
  'xh-ZA': 'Xhosa',
  'zh': 'Chinese',
  'zh-CN': 'Simplified Chinese',
  'zh-HK': 'Traditional Chinese',
  'zh-MO': 'Traditional Chinese',
  'zh-SG': 'Simplified Chinese',
  'zh-TW': 'Traditional Chinese',
  'zu-ZA': 'Zulu ',
};

const getLanguageNameByCultureCode = (cultureCode: string) => cultureCodeMapper[cultureCode] || '';

const formatCultureCode = (cultureCode: string) : string => {
  const language = getLanguageNameByCultureCode(cultureCode);
  return language ? `${language}, ${cultureCode}` : cultureCode;
};

export default getLanguageNameByCultureCode;

export {
  getLanguageNameByCultureCode,
  formatCultureCode,
};
