import React from 'react';
import { useTranslation } from 'react-i18next';

type ProfileDropdownItemProps = {
  text: string,
  url?: string,
  element?: JSX.Element,
};
const ProfileDropdownItem = ({ text, url, element }: ProfileDropdownItemProps) => {
  const { t } = useTranslation();
  return (
    <li className="sdds-nav__avatar-item">
      {url && <a href={url}>{t(text)}</a>}
      {element}
    </li>
  )
};

export default ProfileDropdownItem;
