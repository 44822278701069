import React, { useState, useEffect, useCallback } from 'react';
import { getDisturbanceMessages } from '../../adapters/DisturbanceMessageAdapter';
import { getUsersByScaniaIds } from '../../adapters/UserAdapter';
import DisturbanceMessageTable from './DisturbanceMessageTable';
import AddDisturbanceMessageModal from './AddDisturbanceMessageModal';
import { Spinner, Dropdown, Banner, Toast, useToast } from '@cd/sdds-common-components-react';
import './DisturbanceMessages.css';
import { t } from 'i18next';

const DisturbanceMessages = () => {
  const [messages, setMessages] = useState<Array<DisturbanceMessageType>>([]);
  const [allMessages, setAllMessages] = useState<Array<DisturbanceMessageType>>([]);
  const [allSystems, setAllSystems] = useState<Array<string>>([]);
  const [allTypes, setAllTypes] = useState<Array<string>>([]);
  const [users, setUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [paginationReset, setPaginationReset] = useState(false);
  const [systemFilter, setSystemFilter] = useState('');
  const [showAddDisturbanceMessageModal, setShowAddDisturbanceMessageModal] = useState<boolean>(false);
  const [apiDisturbanceMessage, setApiDisturbanceMessage] = useState<AsyncStateType>({ loading: false, error: false });
  const [apiUsers, setApiUsers] = useState<AsyncStateType>({ loading: false, error: false });
  const { toastQueue, addToast } = useToast();

  const getUsers = (scaniaIds: Array<string>) => {
    setApiUsers({ loading: true, error: false });
    getUsersByScaniaIds(scaniaIds)
      .then((response: any) => {
        setUsers(response.data);
        setApiUsers({ loading: false, error: false });
      })
      .catch((error: Error) => {
        console.log('getUsersError: ', error);
        setApiUsers({ loading: false, error: true });
      });
  };

  const loadDisturbanceMessages = useCallback((): void => {
    setApiDisturbanceMessage({ loading: true, error: false });
    getDisturbanceMessages()
      .then((response: any) => {
        let systems = response.data.map((data: any) => data.systemName);
        systems = new Set(systems);
        systems = [...systems];
        systems = systems.sort();
        setAllSystems(systems);
        let types = response.data.map((data: any) => data.type);
        types = new Set(types);
        types = [...types];
        types = types.sort();
        setAllTypes(types);
        setMessages(response.data);
        setAllMessages(response.data)
        const uniqueIds = response.data.map((item: any) => item.createdBy)
          .filter((item: any) => item !== null)
          .filter((v: any, i: any, a: any) => a.indexOf(v) === i)
          .map((item: string) => item.toLowerCase());
        getUsers(Array.from(new Set(uniqueIds)));
        setApiDisturbanceMessage({ loading: false, error: false });
      })
      .catch((error: Error) => {
        console.error(error);
        setApiDisturbanceMessage({ loading: false, error: true });
      });
  }, []);

  const handleDisturbanceMessageAdded = () => {
    addToast({ text: 'Disturbance message added', type: 'success' });
    loadDisturbanceMessages();
  };

  const getStatus = (startTime: string, endTime: string): string => {
    const start = new Date(startTime).getTime();
    const end = endTime ? new Date(endTime).getTime() : null;
    const now = new Date().getTime();
    if (start > now) {
      // Pending, not yet started
      return 'Pending';
    }
    if (end && end < now) {
      // Expired
      return 'Expired';
    }
    // Active
    return 'Active';
  };

  const filterTableData = () => {

    let filteredMessages = [...allMessages]
    if (statusFilter !== '')
      filteredMessages = filteredMessages.filter((message) => {
        const status = getStatus(message.startTime, message.endTime);
        return status === statusFilter;
      });

    if (typeFilter !== '')
      filteredMessages = filteredMessages.filter((message) => {
        return message.type === typeFilter;
      });

    if (systemFilter !== '')
      filteredMessages = filteredMessages.filter((message) => {
        return message.systemName === systemFilter;
      });
    setMessages(filteredMessages);
  }

  const clearFilter = (filterSelector: string) => {
    switch (filterSelector) {
      case 'status':
        if (statusFilter === '') return;
        setStatusFilter('');
        break;
      case 'type':
        if (typeFilter === '') return;
        setTypeFilter('');
        break;
      case 'system':
        if (systemFilter === '') return;
        setSystemFilter('');
        break;
      default: ;
    }
  }

  useEffect(() => {
    if (statusFilter === '' && typeFilter === '' && systemFilter === '') {
      setMessages(allMessages);
      return;
    }
    filterTableData();
    setPaginationReset(!paginationReset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, typeFilter, systemFilter]);



  useEffect(() => {
    loadDisturbanceMessages();
  }, [loadDisturbanceMessages]);

  return (
    <>
      <div className="sdds-container-fluid content-wrapper" id="abc">        
        {allSystems.length > 0 && allTypes.length > 0 &&
          (<div className="sdds-row mt-32">
            <h5 className='filter-label'>{t("TS_temp:FilterBy")}</h5>
            <div className="sdds-col filter-container">

              <div className='filter-dropdown filter-item'>
                <Dropdown data-testid="status-filter" data={['Active', 'Pending', 'Expired']} placeholder={t("TS_core:Status")} selectedValue={statusFilter} setSelectedValue={setStatusFilter}></Dropdown>
                <div className="close-icon" onClick={() => clearFilter('status')} onKeyPress={() => clearFilter('status')} role="button" tabIndex={0}>
                  <img src={'../../assets/CloseIcon.svg'} alt={'clear status filter'} height="20" width="20" />
                </div>
              </div>
              <div className='filter-dropdown filter-item'>
                <Dropdown data={allTypes} placeholder={t("TS_core:Type")} selectedValue={typeFilter} setSelectedValue={setTypeFilter}></Dropdown>
                <div className="close-icon" onClick={() => clearFilter('type')} onKeyPress={() => clearFilter('type')} role="button" tabIndex={0}>
                  <img src={'../../assets/CloseIcon.svg'} alt={'clear type filter'} height="20" width="20" />
                </div>
              </div>
              <div className='filter-dropdown filter-item'>
                <Dropdown data={allSystems} placeholder={t("System")} selectedValue={systemFilter} setSelectedValue={setSystemFilter}></Dropdown>
                <div className="close-icon" onClick={() => clearFilter('system')} onKeyPress={() => clearFilter('system')} role="button" tabIndex={0}>
                  <img src={'../../assets/CloseIcon.svg'} alt={'clear system filter'} height="20" width="20" />
                </div>
              </div>

            </div>
            <div className="sdds-col new-message-button">
              <button
                type="button"
                className="sdds-btn sdds-btn-sm sdds-btn-primary"
                onClick={() => setShowAddDisturbanceMessageModal(true)}
              >
                {t("TS_core:AddNewMessage")}
              </button>
            </div>
          </div>
          )}

        {(apiDisturbanceMessage.loading) && (<Spinner />)}
        {
          (apiDisturbanceMessage.error || apiUsers.error) && (
            <div className="sdds-row">
              <div className="sdds-col">
                <Banner text={t("TS_temp:SystemUnreachable")} type="error" />
              </div>
            </div>
          )
        }
        {!apiDisturbanceMessage.error && !apiUsers.error && !apiDisturbanceMessage.loading && messages.length === 0 && (
          <div className="sdds-row">
            <div className="sdds-col">
              <Banner text={t("TS_temp:NoRecordsFound")} type="error" />
            </div>
          </div>

        )}
        {
          !apiDisturbanceMessage.error && !apiUsers.error && !apiDisturbanceMessage.loading
          && messages.length > 0 && (
            <div className="sdds-row">
              <div className="sdds-col">
                <DisturbanceMessageTable messages={messages} users={users} paginationReset={paginationReset} />
              </div>
            </div>
          )
        }
      </div>
      <AddDisturbanceMessageModal
        show={showAddDisturbanceMessageModal}
        onClose={setShowAddDisturbanceMessageModal}
        onAdd={handleDisturbanceMessageAdded}
      />
      <Toast toastQueue={toastQueue} />
    </>
  );
};

export default DisturbanceMessages;
