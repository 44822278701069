import React from "react";
import styles from "./Steppers.module.css"
import DoneSvg from './done.svg'
import clsx from 'clsx';
import { t } from "i18next";

const Steppers = (step: any) => {
    return (
        <div className={clsx(styles.wrapper)}>
            <div className={styles.step}>
                <div className={clsx(styles.stepDetails, { [styles.active]: step.step === 'properties' })}>
                    <p className={clsx(styles.circle, {
                        [styles.circle_active]: step.step === 'properties',
                        [styles.done]: step.step === 'edit' || step.step === 'review'
                    }
                    )}
                    >
                        {step.step === 'edit' || step.step === 'review' ? <img src={DoneSvg} alt="done" /> : '1'}
                    </p>
                    <p>{t("TS_temp:ChooseSettings")}</p>
                </div>
            </div>
            <div className={styles.step}>
                <div className={clsx(styles.stepDetails, { [styles.active]: step.step === 'edit' })}>
                    <p className={clsx(styles.circle, { [styles.circle_active]: step.step === 'edit', [styles.done]: step.step === 'review' })}>
                        {step.step === 'review' ? <img src={DoneSvg} alt="done" /> : '2'}
                    </p>
                    <p>{t("TS_temp:AddEditMessage")}</p>
                </div>
            </div>
            <div className={styles.stepLast}>
                <div className={clsx(styles.stepDetails, { [styles.active]: step.step === 'review' })}>
                    <p className={clsx(styles.circle, { [styles.circle_active]: step.step === 'review' })}>3</p>
                    <p>{t("TS_temp:ReviewMessage")}</p>
                </div>
            </div>
        </div>
    )
}

export default Steppers