import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const utcFormatFromString = (datetime: string) : string => {
  if (datetime === 'now') {
    return dayjs.utc().format('YYYY-MM-DD HH:mm:ss');
  }
  return datetime ? dayjs.utc(datetime).format('YYYY-MM-DD HH:mm:ss') : '-';
};

const utcFormatFromDate = (datetime: Date) : string => dayjs(datetime).utc().format('YYYY-MM-DD HH:mm:ss');

export {
  utcFormatFromString,
  utcFormatFromDate,
};
